<template>
  <div
    v-if="path.indexOf('/exportPdf/') === -1"
    class="footer-wrap"
    :class="[
      path === '/work' ||
      path === '/leasingStrategy' ||
      path === '/leasingPlan' ||
      path === '/planningProject' ||
      path === '/leasingAnalysis' ||
      path === '/investmentAimMange' ||
      path === '/berthInvestmentManage' ||
      path === '/implement' ||
      path === '/investmentFollowDetail' ||
      path === '/investmentAimManageDetail' ||
      path === '/berthInvestmentManageDetail' ||
      path === '/shopsSinged' ||
      path === '/merchantReports' ||
      path === '/merchantsSet' ||
      path === '/manageAccount/division' ||
      path === '/manageAccount/role' ||
      path === '/manageAccount/member' ||
      path === '/manageRights/surplus' ||
      path === '/manageRights/allot' ||
      path === '/manageRights/records' ||
      path === '/managePrivilege' ||
      path === '/brandUpdateRescords' ||
      path === '/systemLog' ||
      path === '/personalInfo' ||
      path === '/comparisonContent' ||
      path === '/merchantsRecommend' ||
      path === '/evaluationAnalysis' ||
      path.indexOf('/earlywarningAnalysis/') !== -1 ||
      path === '/earlywarningAnalysis/merchantsRecommend' ||
      path === '/investmentFollowManage' ||
      path === '/visualization' ||
      path === '/shopSet' ? 'footer-width' : '']"
  >
    <div class="footer-content">
      <p class="chart">
        <el-popover
          popper-class="custom-popover"
          ref="popover"
          placement="top"
          title=""
          width="100%"
          trigger="hover"
          content=""
        >
          <img :src="src">
          <div>
            <p>专属客服：{{kefuname}}</p>
            <p>微信号：{{kefuwechatname}}</p>
          </div>
        </el-popover>
        <span v-if="!isGxjt" v-popover:popover>售后客服微信</span>
        <a v-if="!isGxjt" href="http://www.winshangdata.com">赢商大数据</a>
        <a v-if="!isGxjt" href="http://www.winshang.com">赢商网</a>
        <span v-if="!isGxjt" v-popover:popover1>赢商公众号</span>
        <el-popover
          popper-class="custom-popover"
          ref="popover1"
          placement="top"
          title=""
          width="100%"
          trigger="hover"
          content=""
        >
          <img
            src="https://jscss.winshang.com/img/applets-offiacount.jpg"
            alt=""
          >
        </el-popover>
      </p>
      <p class="record">Copyright @ 2009-{{lastYear}} All Rights Reserved 粤ICP备10067299号</p>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { getCookie } from '@/utils'
export default {
  data () {
    return {
      src: '',
      kefuname: '',
      kefuwechatname: '',
      path: '',
      lastYear: ''
    }
  },
  watch: {
    '$route.path': function (newVal, oldVal) {
      this.path = newVal
    }
  },
  mounted () {
    this.lastYear = new Date().getFullYear()
    if (getCookie('wisdom_token')) {
      this.path = this.$route.path
      this.axios.post(api.getKeFuwetchatCode)
        .then((res) => {
          if (res.data.data) {
            this.src = res.data.data.kefuwetchatcode
            this.kefuname = res.data.data.kefuname
            this.kefuwechatname = res.data.data.kefuwechatname
          } else {
            this.src = 'https://jscss.winshang.com/img/applets-offiacount-server.jpg'
            this.kefuname = '小云'
            this.kefuwechatname = 'win18578675071'
          }
        })
    } else {
      this.src = 'https://jscss.winshang.com/img/applets-offiacount-server.jpg'
      this.kefuname = '小云'
      this.kefuwechatname = 'win18578675071'
    }
  }
}
</script>

<style lang="stylus" scoped>
.footer-wrap
  position relative
  z-index 1
  height 82px
  background-color #1e1e23
  // box-shadow 0px 1px 12px 0px rgba(0, 0, 0, 0.31)
  text-align center
  .chart
    color rgba(255, 255, 255, 0.5)
    font-size 12px
    padding-top 20px
    span, a
      margin 0 6px
      color rgba(255, 255, 255, 0.5)
      cursor pointer
  .record
    color rgba(165, 165, 167, 0.5)
    font-size 12px
    margin-top 12px
  .pt-40
    padding-top 40px
</style>
