<template>
  <div id="app">
    <template v-if="
      path === '/Header' ||
      path === '/Sidebar' ||
      path.indexOf('/embedded/') !== -1 ||
      path.indexOf('/enterprise') !== -1">
      <router-view />
    </template>
    <template v-else>
      <template v-if="!isLogin && !isProjectBigScreen && !isFormConfiguration && !isBasicForm">
        <div class="page-main">
          <div
            v-if="!isMarketReserch"
            :class="[positonTop ? 'positonTop' : '']"
          >
            <Header></Header>
          </div>
          <div
            class="page-content"
            :style="{'min-height': clientHeight + 'px'}"
          >
            <router-view />
          </div>
        </div>
        <Footer v-if="
          !isDetaching &&
          !isBrandMap &&
          !isReportCenter &&
          !isMarketReserch &&
          !isWorkSetting &&
          !isWorkSubmit"></Footer>
      </template>
      <template v-else>
        <router-view />
      </template>
    </template>
  </div>
</template>

<script>
import '@/assets/stylus/index.styl'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
  components: {
    Header,
    Footer
  },
  data () {
    return {
      clientHeight: 0,
      path: '',
      positonTop: false
    }
  },
  computed: {
    isLogin () {
      return this.$route.name === 'login'
    },
    isDetaching () {
      return this.$route.name === 'detachingShops'
    },
    isBrandMap () {
      return this.$route.name === 'brandMap'
    },
    isProjectBigScreen () {
      return this.$route.name === 'projectBigScreen'
    },
    isFormConfiguration () {
      return this.$route.name === 'formConfiguration'
    },
    isMarketReserch () {
      return this.$route.name === 'marketReserch'
    },
    isFormConfig () {
      return this.$route.name === 'formConfiguration'
    },
    isBasicForm () {
      return this.$route.name === 'basicForm' || this.$route.name === 'basicFormTest'
    },
    isWorkSetting () {
      return this.$route.name === 'workSetting'
    },
    isWorkSubmit () {
      return this.$route.name === 'workSubmit'
    },
    // isArticle () { // 2021年2月8日11:39:57 产品恢复知识库底部
    //   return this.$route.name === 'article'
    // },
    isReportCenter () {
      return this.$route.name === 'reportCenter'
    }
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  created () {
    document.body.setAttribute('class', 'cover-page')
    setTimeout(() => {
      document.body.setAttribute('class', '')
    }, 300)
  },
  mounted () {
    if (this.$route.name === 'detachingShops' || this.$route.name === 'article') {
      this.clientHeight = `${document.documentElement.clientHeight}` - 122
    } else if (this.$route.name === 'reportCenter') {

    } else {
      this.clientHeight = `${document.documentElement.clientHeight}` - 238
    }
    // setTimeout(() => {
    //   if (this.$route.name === 'formatManage') {
    //     document.body.style.overflowY = 'hidden'
    //   }
    // }, 800)
    // window.addEventListener('scroll', this.handleScroll)
  },
  watch: {
    '$route.path': function (newVal, oldVal) {
      this.path = newVal
    }
  },
  methods: {
    handleScroll () {
      const scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
      if (scrollTop > 188) {
        this.positonTop = true
      } else {
        this.positonTop = false
      }
    }
  }
}
</script>

<style lang="stylus">
#app
  // font-family Avenir, Helvetica, Arial, sans-serif
  background-color #1E1E23
  color #fff
.page-main
  // background-color #1E1E23
  overflow hidden
.positonTop
  position fixed
  left 0
  top 0 !important
  z-index 1111
  width 100%
.cover-page:before
  content ''
  position fixed
  width 100%
  height 100vh
  background-color #1E1E23
  z-index 999
.el-cascader-panel .el-radio
  width 132px
  height 34px
  line-height 34px
  padding 0 10px
  z-index 10
  position absolute
.el-cascader-panel .el-radio__input
  visibility hidden
.el-cascader-panel .el-cascader-node__postfix
  top 10px
</style>
