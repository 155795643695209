import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './utils/http'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import { setCookie, getCookie, getQueryVariable } from './utils'
import { getUserInfo, getUserInterests } from './utils/initLoad'
// 导入组件库
import FormDesign from './views/workOrderCenter/dynamicForm/packages/index'
import './views/workOrderCenter/dynamicForm/packages/form/src/form-design.css'
// 注册组件库
// 以下为bpmn工作流绘图工具的样式
import 'bpmn-js/dist/assets/diagram-js.css' // 左边工具栏以及编辑节点的样式
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn.css'
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-codes.css'
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css'
// highlight.js代码高亮插件
import Highlight from './utils/highlight' // 这里是你项目highlight.js所在路径
import '@/components/bpmnBuilder/css/bpmn.styl'
Vue.use(Highlight)
Vue.use(FormDesign)
Vue.config.productionTip = false

Vue.use(ElementUI)

Vue.prototype.axios = axios

if (getQueryVariable('token') && getQueryVariable('uid')) {
  const userid = {
    key: 'wisdom_user',
    value: getQueryVariable('uid'),
    domain: document.domain.split('.').slice(-2).join('.'),
    expireDays: 0
  }
  setCookie(userid)
  const token = {
    key: 'wisdom_token',
    value: getQueryVariable('token'),
    domain: document.domain.split('.').slice(-2).join('.'),
    expireDays: 0
  }
  setCookie(token)
}

let gaodeDomain = ''
let shulieDomain = ''
let winshangDomain = ''
if (document.domain.indexOf('local.winshangdata') > -1) {
  gaodeDomain = 'http://prewbms.winshangdata.com/gdwscrm'
  shulieDomain = 'http://prewbmsset.winshangdata.com/set/chameleon/token/login?app=bi&redirect=/set/account/sso?url=/set/'
  winshangDomain = 'http://prewbms.winshangdata.com'
} else if (window.location.href.indexOf('http://') > -1) {
  gaodeDomain = 'http://' + document.domain + '/gdwscrm'
  shulieDomain = 'http://' + document.domain + '/set/chameleon/token/login?app=bi&redirect=/set/account/sso?url=/set/'
  winshangDomain = 'http://' + document.domain
} else {
  gaodeDomain = 'https://' + document.domain + '/gdwscrm'
  shulieDomain = 'https://' + document.domain + '/set/chameleon/token/login?app=bi&redirect=/set/account/sso?url=/set/'
  winshangDomain = 'https://' + document.domain
}
// if (document.domain.indexOf('local') !== -1 || document.domain.indexOf('prewbms') !== -1) {
//   gaodeDomain = 'http://prewbms.winshangdata.com/gdwscrm'
//   shulieDomain = 'http://prewbmsset.winshangdata.com/chameleon/token/login?app=bi&redirect=/set/account/sso?url=/set/'
//   winshangDomain = 'prewbms.winshangdata.com'
// } else if (document.domain.indexOf('wbms1') !== -1) {
//   gaodeDomain = 'https://wbms1.winshangdata.com/gdwscrm'
//   shulieDomain = 'https://wbms1.winshangdata.com/set/chameleon/token/login?app=bi&redirect=/set/account/sso?url=/set/'
//   winshangDomain = 'wbms1.winshangdata.com'
// } else if (document.domain.indexOf('wbms2') !== -1) {
//   gaodeDomain = 'https://wbms2.winshangdata.com/gdwscrm'
//   shulieDomain = 'https://wbms2.winshangdata.com/set/chameleon/token/login?app=bi&redirect=/set/account/sso?url=/set/'
//   winshangDomain = 'wbms2.winshangdata.com'
// } else {
//   winshangDomain = 'wbms.winshangdata.com'
//   gaodeDomain = 'https://wbms.winshangdata.com/gdwscrm'
//   shulieDomain = 'https://wbms.winshangdata.com/set/chameleon/token/login?app=bi&redirect=/set/account/sso?url=/set/'
// }
Vue.prototype.$gaodeDomain = gaodeDomain
Vue.prototype.$shulieDomain = shulieDomain
Vue.prototype.$winshangDomain = winshangDomain

// 是否为广西交投
// console.log(document.domain)
Vue.prototype.isGxjt = document.domain.indexOf('gxjt.winshangdata.com') > -1
if (document.domain.indexOf('gxjt.winshangdata.com') > -1) {
  document.title = '商业物业管理平台系统'
} else {
  document.title = '赢商大数据云商管系统'
}

router.beforeEach((to, from, next) => {
  const uid = getCookie('wisdom_user') || ''
  const token = getCookie('wisdom_token') || ''
  if (!uid) {
    if (to.name === 'login') {
      next()
    } else {
      next({
        name: 'login'
      })
    }
  } else {
    Promise.all([getUserInterests(uid), getUserInfo(token, uid)])
      .then(() => {
        next()
      })
  }
})
router.afterEach((to, from, next) => {
  if (router.history.current.path === '') {
    console.log('不滚动到顶部')
  } else {
    window.scrollTo(0, 0)
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
