var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[(
    _vm.path === '/Header' ||
    _vm.path === '/Sidebar' ||
    _vm.path.indexOf('/embedded/') !== -1 ||
    _vm.path.indexOf('/enterprise') !== -1)?[_c('router-view')]:[(!_vm.isLogin && !_vm.isProjectBigScreen && !_vm.isFormConfiguration && !_vm.isBasicForm)?[_c('div',{staticClass:"page-main"},[(!_vm.isMarketReserch)?_c('div',{class:[_vm.positonTop ? 'positonTop' : '']},[_c('Header')],1):_vm._e(),_c('div',{staticClass:"page-content",style:({'min-height': _vm.clientHeight + 'px'})},[_c('router-view')],1)]),(
        !_vm.isDetaching &&
        !_vm.isBrandMap &&
        !_vm.isReportCenter &&
        !_vm.isMarketReserch &&
        !_vm.isWorkSetting &&
        !_vm.isWorkSubmit)?_c('Footer'):_vm._e()]:[_c('router-view')]]],2)}
var staticRenderFns = []

export { render, staticRenderFns }