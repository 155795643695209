<template>
  <div
    class="header-wrap"
    v-if="path.indexOf('/exportPdf/') === -1"
  >
    <div class="nav-wrap">
      <div class="nav-top">
        <div class="nav-top-left fl">
          <span>{{user.company}}-{{!isGxjt ? '赢商大数据云商管系统' : '商业物业管理平台系统'}}</span>
          <span>有效期至：{{user.endtime}}</span>
        </div>
        <div class="nav-top-right fr">
          <el-menu
            class="el-menu-demo"
            mode="horizontal"
            menu-trigger="hover"
            @select="handleSelect"
          >
            <el-submenu
              index="1"
              popper-class="custom-popup-menu"
            >
              <template slot="title"> <span class="icon-user"></span> {{user.userName || '总账号'}}</template>
              <el-menu-item
                index="1-1"
                @click="toPerson(0)"
              >我的权益</el-menu-item>
              <el-menu-item
                index="2-2"
                @click="toPerson(1)"
              >修改密码</el-menu-item>
              <el-menu-item
                index="3-3"
                @click="logOut()"
              >退出登录</el-menu-item>
            </el-submenu>
            <el-menu-item
              index="2"
              @click="toUserMsg()"
            >
              <em> <span class="icon-message"></span> 消息中心</em>
            </el-menu-item>
            <el-menu-item
              index="3"
              v-popover:popover1
            >
              <span class="icon-phone"></span>
              移动终端
              <el-popover
                ref="popover1"
                placement="top"
                title=""
                width="100%"
                trigger="hover"
                content=""
              >
                <div class="applets-wrap">
                  <div class="applets-flex">
                    <div class="applets fl">
                      <img
                        class="applets-img"
                        :src="wbmsMpUrl"
                        alt=""
                      >
                      <p>赢商云商管小程序</p>
                    </div>
                    <div class="applets fr">
                      <img
                        class="applets-img"
                        :src="storeMpUrl"
                        alt=""
                      >
                      <p>商户自助小程序</p>
                    </div>
                  </div>
                </div>
              </el-popover>
            </el-menu-item>
            <el-menu-item
              index="4"
              @click="toKnowledgeBase()"
            >
              <li><span class="icon-knowledge"></span> {{!isGxjt ? '赢商' : ''}}知识库</li>
            </el-menu-item>
            <!-- <el-menu-item index="5">
              <li><span class="icon-big-data"></span> 大屏管理</li>
            </el-menu-item> -->
            <el-menu-item
              index="6"
              v-if="showBI"
              @click="toBiAnalysis()"
            >
              <li><span class="icon-match-factor"></span> BI管理</li>
            </el-menu-item>
            <el-menu-item
              index="7"
              @click="goLink('/')"
            >
              <li><span class="icon-house"></span> {{!isGxjt ? '云商管' : ''}}首页</li>
            </el-menu-item>
          </el-menu>
        </div>
      </div>
      <div class="nav-bottom">
        <div class="nav-bottom-left fl">
          <div class="logo-flex fl">
            <img :src="logoUrl" />
          </div>
          <div class="nav-list fl">
            <ul>
              <li
                :class="{'active' : path === '/'}"
                @click="goLink('/')"
              >
                <span>工作台</span>
              </li>
              <li
                :class="{'active' : path === '/dataSmartCockpit' || path === '/storeEarlywarning'}"
                @click="goLink('/dataSmartCockpit')"
              >
                <span>数智驾舱</span>
              </li>
              <li
                :class="{'active' : path === '/reportCenter' || path === '/reportCenterHome'}"
                v-popover:popoverReport
              >
                <span @click="toReportCenter">报表中心</span>
                <!-- <el-popover
                  popper-class="custom-popover-menu"
                  ref="popoverReport"
                  placement="top"
                  width="100%"
                  trigger="hover"
                >
                  <ul>
                    <li
                      v-for="(item) in reportCenterList"
                      :key="item.id"
                      @click="toReportCenter(item)"
                    >{{item.name}}</li>
                  </ul>
                </el-popover> -->
              </li>
              <li
                :class="{'active' : path === '/selfBrandList'}"
                @click="goLink('/selfBrandList')"
              >
                <span>云端品牌库</span>
              </li>
              <li
                :class="{'active' : path === '/projectList'}"
                @click="goLink('/projectList')"
              >
                <span>云端项目库</span>
              </li>
              <li
                :class="{'active' : path === '/brandTendency'}"
                @click="goLink('/brandTendency')"
              >
                <span>商业洞察</span>
              </li>
            </ul>
          </div>
        </div>
        <div
          class="nav-bottom-right fr"
          @click="goLink('/managerCenter')"
        >
          <i>
            <span class="el-icon-setting"></span>
            <span> 管理中心</span>
          </i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getCookie, removeCookie } from '@/utils'
import api from '@/api'
export default {
  data () {
    return {
      path: '',
      appletsShow: false,
      isEmptyReportList: false,
      modulesList: [],
      logoUrl: 'https://img3.winshang.com/wbms/logo.png',
      wbmsMpUrl: 'https://img3.winshang.com/wbms/wbms-applet.jpg',
      storeMpUrl: 'https://img3.winshang.com/wbms/store-applet.jpg',
      goUrl: ''
    }
  },
  watch: {
    '$route.path': function (newVal, oldVal) {
      this.path = newVal
    }
  },
  beforeDestroy () {
  },
  mounted () {
    this.path = this.$route.path
    if (getCookie('wisdom_token')) {
      this.getDataUserLogo()
    }
    if (getCookie('wisdom_token')) {
      this.getReportCenterList()
      this.getDataPermission()
    }
    this.setMpUrl()
  },
  computed: {
    ...mapGetters({
      user: 'user',
      token: 'token'
    }),
    showBI () {
      return this.user.confIds.find(item => item.id === 128)
    },
    url () {
      let temUrl = ''
      if (process.env.NODE_ENV === 'development') {
        temUrl = 'http://' + document.domain + ':8080'
      }
      return temUrl
    },
    winshangURL () {
      return 'http://wbms.winshangdata.com'
    }
  },
  methods: {
    getDataUserLogo () {
      const params = {
      }
      this.axios.get(api.getCompanyLogo, params).then(res => {
        if (res.data.code === 0) {
          this.logoUrl = res.data.data
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    isIE () { // ie?
      if (!!window.ActiveXObject || 'ActiveXObject' in window) { return true } else { return false }
    },
    getDataPermission () {
      const params = {
      }
      this.axios.post(api.getModularList, params).then(res => {
        if (res.data.code === 0 && res.data.data) {
          const resData = res.data.data
          this.modulesList = resData
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getReportCenterList () {
      const params = {
      }
      this.axios.post(api.getBBList, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          if (resData && resData.length === 0) {
            this.isEmptyReportList = true
          }
        }
      })
    },
    toReportCenter () {
      if (!this.isEmptyReportList) {
        this.goLink('/reportCenterHome')
      } else {
        this.$message({
          type: 'warning',
          message: '抱歉，暂无权限访问此应用模块！'
        })
      }
    },
    toEarly () {
      window.open('/#/early', '_blank')
    },
    handleSelect (key, keyPath) {
      if (key === '3') {
        this.appletsShow = !this.appletsShow
      } else {
        this.appletsShow = false
      }
    },
    logOut () {
      this.axios.post(api.loginOut).then(res => {
        removeCookie('wisdom_token')
        removeCookie('wisdom_user')
        localStorage.removeItem('wisdom_interests')
        this.$message({
          message: '退出成功'
        })
        window.location.href = this.url + '/#/login?backurl=' + window.location.href
        if (this.isIE()) {
          window.location.reload(true)
        }
      })
    },
    goLink (path) {
      localStorage.removeItem('saveScreen')
      this.$router.push({
        path: path
      })
    },
    toPerson (key) {
      if (key) {
        window.open(this.url + '/#/personalInfo?index=2', '_blank')
      } else {
        window.open(this.url + '/#/personalInfo', '_blank')
      }
    },
    toKnowledgeBase () {
      window.open(this.url + '/#/knowledgeBase/322', '_blank')
    },
    toBusiness () {
      window.open(this.url + '/#/business', '_blank')
    },
    toBiAnalysis () {
      // for (let i = 0; i < this.modulesList.length; i++) {
      //   const element = this.modulesList[i]
      //   console.log('element', element)
      // }
      const list = this.modulesList.filter(item => {
        return item.id === 128
      })
      if (list.length) {
        const tempObj = list[0]
        if (tempObj.flag) {
          if (tempObj.userFlag) {
            window.open(this.$shulieDomain + 'dashboard&token=' + this.token, '_blank')
          } else {
            this.$message({
              type: 'warning',
              message: '暂无权限访问此页！'
            })
          }
        } else {
          this.$message({
            type: 'warning',
            message: '抱歉，集团暂未开通此应用模块！'
          })
        }
      }
    },
    toBiHref (id) {
      if (id === 1) {
        window.open(this.$gaodeDomain + '/BI/rent?tokenval=' + this.token, '_blank')
      } else if (id === 2) {
        window.open(this.$gaodeDomain + '/BI/financial?tokenval=' + this.token, '_blank')
      } else {
        window.open(this.$gaodeDomain + '/BI/operating?tokenval=' + this.token, '_blank')
      }
    },
    toUserMsg () {
      window.open(this.url + '/#/notifications', '_blank')
    },
    setMpUrl () {
      if (document.domain.indexOf('wbms1') !== -1) {
        this.wbmsMpUrl = 'https://img3.winshang.com/wbms/demo-wbms-applet.jpeg'
        this.storeMpUrl = 'https://img3.winshang.com/wbms/demo-store-applet.jpeg'
      } else if (document.domain.indexOf('wbms2') !== -1) {
        this.wbmsMpUrl = 'https://img3.winshang.com/wbms/pre-wbms-applet.jpeg'
        this.storeMpUrl = 'https://img3.winshang.com/wbms/pre-store-applet.jpeg'
      } else {
        this.wbmsMpUrl = 'https://img3.winshang.com/wbms/wbms-applet.jpg'
        this.storeMpUrl = 'https://img3.winshang.com/wbms/store-applet.jpg'
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.icon-user:before
  content '\e6f2'
  vertical-align text-bottom
.icon-phone:before
  content '\e6f8'
  font-size 14px
.icon-knowledge:before
  content '\e6f9'
.icon-big-data:before
  content '\e6fa'
  font-size 14px
.icon-match-factor:before
  content '\e72d'
  font-size 14px
.header-wrap
  // overflow hidden
  box-shadow 0px 6px 51px 0px rgba(18, 18, 20, 0.78)
.nav-wrap
  width 100%
  height 122px
  background-color #272930
  .nav-top
    height 35px
    line-height 35px
    .nav-top-left
      margin-left 24px
      span
        color #9B9B9B
        font-size 14px
        font-weight 400
        margin-right 24px
    .nav-top-right
      margin-right 12px
  .nav-bottom
    width 100%
    background-color #272930
    height 88px
    line-height 86px
    display inline-block
    .nav-bottom-left
      margin-left 24px
      .logo-flex
        display flex
        height 88px
        width 113px
        align-items center
        overflow hidden
      .nav-list
        margin-left 10px
        li
          float left
          padding 0 20px
          margin-left 12px
          margin-right 12px
          cursor pointer
          &:hover span:first-child
            color #fff
            padding-bottom 30px
            border-bottom 3px solid #FFA134
          span:first-child
            font-size 18px
            color #A5A5A7
            padding 0 12px
    .nav-bottom-right
      margin-right 24px
      cursor pointer
      i
        border 1px solid #FFA134
        padding 8px 15px
        border-radius 18px
        color #FFA134
        span
          color #FFA134
        a
          color #FFA134
.active>span
  color #fff !important
  padding-bottom 30px !important
  border-bottom 3px solid #FFA134
.nav-wrap>>>
  .el-menu--horizontal>.el-submenu:focus .el-submenu__title, .el-menu--horizontal>.el-submenu:hover .el-submenu__title
    color #fff !important
.applets-wrap
  text-align center
  .applets-flex
    // display flex
    align-items center
    .applets
      flex 1
      margin-top 5px
      padding 0 5px
      p
        color #666
        margin-top 3px
  .applets-img
    width 130px
    height 130px
.bottom-border
  border-bottom 1px solid rgba(255, 255, 255, 0.1)
@media screen and (max-width 1440px)
  .nav-bottom
    .nav-bottom-left
      .nav-list
        li
          margin 0 9px !important
@media screen and (max-width 1400px)
  .nav-bottom
    .nav-bottom-left
      .nav-list
        li
          margin 0 6px !important
</style>
