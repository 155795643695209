var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.path.indexOf('/exportPdf/') === -1)?_c('div',{staticClass:"footer-wrap",class:[
    _vm.path === '/work' ||
    _vm.path === '/leasingStrategy' ||
    _vm.path === '/leasingPlan' ||
    _vm.path === '/planningProject' ||
    _vm.path === '/leasingAnalysis' ||
    _vm.path === '/investmentAimMange' ||
    _vm.path === '/berthInvestmentManage' ||
    _vm.path === '/implement' ||
    _vm.path === '/investmentFollowDetail' ||
    _vm.path === '/investmentAimManageDetail' ||
    _vm.path === '/berthInvestmentManageDetail' ||
    _vm.path === '/shopsSinged' ||
    _vm.path === '/merchantReports' ||
    _vm.path === '/merchantsSet' ||
    _vm.path === '/manageAccount/division' ||
    _vm.path === '/manageAccount/role' ||
    _vm.path === '/manageAccount/member' ||
    _vm.path === '/manageRights/surplus' ||
    _vm.path === '/manageRights/allot' ||
    _vm.path === '/manageRights/records' ||
    _vm.path === '/managePrivilege' ||
    _vm.path === '/brandUpdateRescords' ||
    _vm.path === '/systemLog' ||
    _vm.path === '/personalInfo' ||
    _vm.path === '/comparisonContent' ||
    _vm.path === '/merchantsRecommend' ||
    _vm.path === '/evaluationAnalysis' ||
    _vm.path.indexOf('/earlywarningAnalysis/') !== -1 ||
    _vm.path === '/earlywarningAnalysis/merchantsRecommend' ||
    _vm.path === '/investmentFollowManage' ||
    _vm.path === '/visualization' ||
    _vm.path === '/shopSet' ? 'footer-width' : '']},[_c('div',{staticClass:"footer-content"},[_c('p',{staticClass:"chart"},[_c('el-popover',{ref:"popover",attrs:{"popper-class":"custom-popover","placement":"top","title":"","width":"100%","trigger":"hover","content":""}},[_c('img',{attrs:{"src":_vm.src}}),_c('div',[_c('p',[_vm._v("专属客服："+_vm._s(_vm.kefuname))]),_c('p',[_vm._v("微信号："+_vm._s(_vm.kefuwechatname))])])]),(!_vm.isGxjt)?_c('span',{directives:[{name:"popover",rawName:"v-popover:popover",arg:"popover"}]},[_vm._v("售后客服微信")]):_vm._e(),(!_vm.isGxjt)?_c('a',{attrs:{"href":"http://www.winshangdata.com"}},[_vm._v("赢商大数据")]):_vm._e(),(!_vm.isGxjt)?_c('a',{attrs:{"href":"http://www.winshang.com"}},[_vm._v("赢商网")]):_vm._e(),(!_vm.isGxjt)?_c('span',{directives:[{name:"popover",rawName:"v-popover:popover1",arg:"popover1"}]},[_vm._v("赢商公众号")]):_vm._e(),_c('el-popover',{ref:"popover1",attrs:{"popper-class":"custom-popover","placement":"top","title":"","width":"100%","trigger":"hover","content":""}},[_c('img',{attrs:{"src":"https://jscss.winshang.com/img/applets-offiacount.jpg","alt":""}})])],1),_c('p',{staticClass:"record"},[_vm._v("Copyright @ 2009-"+_vm._s(_vm.lastYear)+" All Rights Reserved 粤ICP备10067299号")])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }