import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/allFunction/index.vue')
  },
  {
    path: '/commonList',
    name: 'commonList',
    component: () => import('@/views/allFunction/commonList.vue')
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('@/views/notifications/index')
  },
  {
    path: '/reportCenterHome',
    name: 'reportCenterHome',
    component: () => import('@/views/workBench/reportCenterHome')
  },
  {
    path: '/dataSmartCockpit',
    name: 'dataSmartCockpit',
    component: () => import('@/views/workBench/dataSmartCockpit')
  },
  {
    path: '/reportCenter',
    name: 'reportCenter',
    component: () => import('@/views/workBench/reportCenter.vue'),
    children: [
      {
        path: '/investmentPlanAnalysis',
        name: 'investmentPlanAnalysis',
        component: () => import('@/views/workBench/investmentPlanAnalysis/index.vue')
      },
      {
        path: '/implementationAnalysis',
        name: 'implementationAnalysis',
        component: () => import('@/views/workBench/implementationAnalysis/index.vue')
      }
    ]
  },
  // {
  //   path: '/test',
  //   component: () => import('@/views/workBench/test.vue')
  // },
  {
    path: '/work',
    name: 'Work',
    meta: { title: '租赁决策概览' },
    component: () => import('@/views/workBench/index.vue'),
    children: [{
      path: '/work',
      name: 'work',
      meta: { title: '租赁决策概览' },
      component: () => import('@/views/workBench/leasingOverview')
    }, {
      path: '/investmentAimMange',
      component: () => import('@/views/workBench/investmentAim/aimIndex.vue'),
      children: [{
        path: '/investmentAimMange',
        name: 'investmentAimMange',
        component: () => import('@/views/workBench/investmentAim/investmentAimMange.vue')
      }, {
        path: '/investmentAimManageDetail',
        name: 'investmentAimManageDetail',
        component: () => import('@/views/workBench/investmentAim/investmentAimManageDetail.vue')
      }]
    }, {
      path: '/detachingShops',
      name: 'detachingShops',
      meta: { title: '铺位拆合管理' },
      component: () => import('@/views/workBench/detachingShops/index.vue')
    }, {
      path: '/shopSet',
      name: 'shopSet',
      component: () => import('@/views/workBench/shopSet/index.vue')
    },
    // {
    //   path: '/leasingOverview',
    //   name: 'leasingOverview',
    //   meta: { title: '租赁决策概览' },
    //   component: () => import('@/views/workBench/leasingOverview/index.vue')
    // },
    {
      path: '/leasingStrategy',
      name: 'leasingStrategy',
      meta: { title: '租赁策略管理' },
      component: () => import('@/views/workBench/leasingStrategy/index.vue')
    },
    {
      path: '/leasingPlan',
      name: 'leasingPlan',
      meta: { title: '租金计划管理' },
      component: () => import('@/views/workBench/leasingPlan/index.vue')
    },
    {
      path: '/planningProject',
      name: 'planningProject',
      meta: { title: '落位计划管理' },
      component: () => import('@/views/workBench/projectPlan/planningProject.vue')
    },
    {
      path: '/leasingAnalysis',
      name: 'leasingAnalysis',
      meta: { title: '租赁决策分析' },
      component: () => import('@/views/workBench/leasingAnalysis/index.vue')
    }]
  },
  {
    path: '/leasingComparison',
    name: 'leasingComparison',
    meta: { title: '租赁决策对比' },
    component: () => import('@/views/workBench/leasingComparison')
  },
  {
    path: '/implement',
    component: () => import('@/views/workBench/implementIndex.vue'),
    children: [{
      path: '/implement',
      component: () => import('@/views/workBench/planManagement/index.vue'),
      children: [{
        path: '/implement',
        name: 'implement',
        meta: { title: '招商进度管控' },
        component: () => import('@/views/workBench/planManagement/index.vue')
      }]
    }, {
      path: '/investmentFollowManage',
      name: 'investmentFollowManage',
      meta: { title: '品牌招商管理' },
      component: () => import('@/views/workBench/investmentFollow/investmentFollowManage.vue')
    }, {
      path: '/investmentFollowDetail',
      name: 'investmentFollowDetail',
      meta: { title: '品牌招商管理详情' },
      component: () => import('@/views/workBench/investmentFollow/investmentFollowDetail.vue')
    }, {
      path: '/visualization',
      name: 'visualization',
      meta: { title: '招商可视管理' },
      component: () => import('@/views/workBench/visualization/index.vue')
    }, {
      path: '/shopsSinged',
      name: 'shopsSinged',
      component: () => import('@/views/workBench/shopsSinged/index.vue')
    }, {
      path: '/berthInvestmentManage',
      component: () => import('@/views/workBench/berthInvestment/berthIndex.vue'),
      children: [{
        path: '/berthInvestmentManage',
        name: 'berthInvestmentManage',
        meta: { title: '铺位招商管理' },
        component: () => import('@/views/workBench/berthInvestment/berthInvestmentManage.vue')
      }, {
        path: '/berthInvestmentManageDetail',
        name: 'berthInvestmentManageDetail',
        meta: { title: '铺位招商详情' },
        component: () => import('@/views/workBench/berthInvestment/berthInvestmentManageDetail.vue')
      }]
    }, {
      path: '/merchantReports',
      name: 'merchantReports',
      meta: { title: '项目招商简报' },
      component: () => import('@/views/workBench/merchantReports/index.vue')
    }, {
      path: '/merchantsSet',
      name: 'merchantsSet',
      component: () => import('@/views/workBench/merchantsSet/index.vue')
    }]
  },
  {
    path: '/contrast',
    name: 'contrast',
    component: () => import('@/views/workBench/projectPlanContrast/index.vue')
  },
  {
    path: '/exportPdf/landingPlanPdf',
    name: 'landingPlanPdf',
    meta: { title: '落位计划管理pdf' },
    component: () => import('@/views/workBench/exportPdf/landingPlanPdf.vue')
  },
  {
    path: '/exportPdf/leasingDecisionsPdf',
    name: 'leasingDecisionsPdf',
    meta: { title: '租赁决策文件pdf' },
    component: () => import('@/views/workBench/exportPdf/leasingDecisionsPdf.vue')
  },
  {
    path: '/competitionComparison',
    name: 'competitionComparison',
    meta: { title: '竞品项目对比' },
    component: () => import('@/views/workBench/competitionComparison/index.vue'),
    children: [{
      path: '/competitionComparison',
      name: 'selectProject',
      meta: { title: '竞品项目对比' },
      component: () => import('@/views/workBench/competitionComparison/selectProject.vue')
    },
    {
      path: '/competitionComparison/comparisonContent',
      name: 'ComparisonContent',
      meta: { title: '竞品项目对比' },
      component: () => import('@/views/workBench/competitionComparison/comparisonContent.vue')
    }]
  },
  {
    path: '/projectList',
    name: 'projectList',
    component: () => import('@/views/projectList/index')
  },
  {
    path: '/embedded/formatManage',
    name: 'formatManage',
    component: () => import('@/views/embedded/formatManage.vue')
  },
  {
    path: '/managerCenter',
    component: () => import('@/views/managerCenter/index'),
    name: 'managerCenter',
    redirect: '/personalInfo',
    meta: { title: '管理中心', icon: 'managerCenter' },
    children: [
      {
        path: '/personalInfo',
        component: () => import('@/views/managerCenter/personalInfo/index'),
        name: 'personalInfo',
        meta: { title: '个人信息管理', icon: 'personalInfo' }
      },
      {
        path: 'manageAccount',
        component: () => import('@/views/managerCenter/manageAccount/index'),
        name: 'manageAccount',
        meta: { title: '部门账号管理', icon: 'manageAccount' },
        children: [
          {
            path: '/manageAccount/division',
            name: 'division',
            component: () => import('@/views/managerCenter/manageAccount/division'),
            meta: { title: '​部门管理', icon: 'index' }
          },
          {
            path: '/manageAccount/role',
            name: 'role',
            component: () => import('@/views/managerCenter/manageAccount/role'),
            meta: { title: '​角色管理', icon: 'role' }
          },
          {
            path: '/manageAccount/member',
            name: 'member',
            component: () => import('@/views/managerCenter/manageAccount/member'),
            meta: { title: '​人员管理', icon: 'member' }
          }
        ]
      },
      {
        path: '/manageRights',
        component: () => import('@/views/managerCenter/manageRights/index'),
        name: 'manageRights',
        meta: { title: '版本权益管理' },
        children: [
          {
            path: '/manageRights/surplus',
            name: 'surplus',
            component: () => import('@/views/managerCenter/manageRights/surplus'),
            meta: { title: '版本权益余量' }
          },
          {
            path: '/manageRights/allot',
            name: 'allot',
            component: () => import('@/views/managerCenter/manageRights/allot'),
            meta: { title: '版本权益分配' }
          },
          {
            path: '/manageRights/records',
            name: 'records',
            component: () => import('@/views/managerCenter/manageRights/records'),
            meta: { title: '版本权益记录' }
          }
        ]
      },
      {
        path: '/managePrivilege',
        component: () => import('@/views/managerCenter/managePrivilege/index'),
        name: 'managePrivilege',
        meta: { title: '联系特权管理' }
      },
      {
        path: '/brandUpdateRescords',
        component: () => import('@/views/managerCenter/brandUpdateRescords/index'),
        name: 'brandUpdateRescords',
        meta: { title: '品牌更新记录' }
      },
      // {
      //   path: '/forewarningSetting',
      //   component: () => import('@/views/managerCenter/forewarningSetting/index'),
      //   name: 'forewarningSetting',
      //   meta: { title: '预警指标设置' }
      // },
      {
        path: '/systemLog',
        component: () => import('@/views/managerCenter/systemLog/index'),
        name: 'systemLog',
        meta: { title: '系统操作日志' }
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/brandDetail',
    name: 'brandDetail',
    component: () => import('@/views/brandDetail/index.vue')
  },
  {
    path: '/brandDetail/brandIndex',
    name: 'brandIndex',
    component: () => import('@/views/brandDetail/brandIndex.vue')
  },
  {
    path: '/brandDetail/brandMap',
    name: 'brandMap',
    component: () => import('@/views/brandDetail/brandMap.vue')
  },
  {
    path: '/projectDetail',
    name: 'projectDetail',
    component: () => import('@/views/projectDetail/index.vue')
  },
  {
    path: '/brandTendency',
    name: 'brandTendency',
    component: () => import('@/views/brandTendency/index.vue')
  },
  {
    path: '/brandTendencySearch',
    name: 'brandTendencySearch',
    component: () => import('@/views/brandTendency/brandTendencySearch.vue')
  },
  {
    path: '/brandTendencyList',
    name: 'brandTendencyList',
    component: () => import('@/views/brandTendency/brandTendencyList.vue')
  },
  {
    path: '/brandTendencyDetail',
    name: 'brandTendencyDetail',
    component: () => import('@/views/brandTendency/brandTendencyDetail.vue')
  },
  {
    path: '/brandTendencyTop',
    name: 'brandTendencyTop',
    component: () => import('@/views/brandTendency/brandTendencyTop.vue')
  },
  {
    path: '/selfBrandList',
    name: 'selfBrandList',
    component: () => import('@/views/selfBrandList/Index')
  },
  {
    path: '/intelBrandList',
    name: 'intelBrandList',
    component: () => import('@/views/selfBrandList/intelBrandList')
  },
  {
    path: '/Header',
    name: 'Header',
    component: () => import('@/components/Header.vue')
  },
  {
    path: '/Sidebar',
    name: 'Sidebar',
    component: () => import('@/components/Sidebar.vue')
  },
  {
    path: '/business',
    name: 'business',
    component: () => import('@/views/biModules/index.vue')
  },
  {
    path: '/early',
    name: 'early',
    component: () => import('@/views/biModules/earlyWarning.vue')
  },
  {
    path: '/marketReserch',
    name: 'marketReserch',
    component: () => import('@/views/biModules/marketReserch.vue')
  },
  {
    path: '/projectBigScreen',
    name: 'projectBigScreen',
    component: () => import('@/views/biModules/projectBigScreen.vue')
  },
  {
    path: '/visualStoreAnalysis',
    name: 'visualStoreAnalysis',
    component: () => import('@/views/biModules/visualStoreAnalysis.vue')
  },
  {
    path: '/storeEarlywarning',
    name: 'storeEarlywarning',
    component: () => import('@/views/storeEarlywarning/index.vue')
  },
  {
    path: '/earlywarningAnalysis',
    name: 'earlywarningAnalysis',
    component: () => import('@/views/storeEarlywarning/earlywarningAnalysis.vue'),
    redirect: '/earlywarningAnalysis/basicData',
    children: [
      {
        path: '/earlywarningAnalysis/basicData',
        name: 'basicData',
        component: () => import('@/views/storeEarlywarning/basicData')
      },
      {
        path: '/earlywarningAnalysis/salesAnalysis',
        name: 'salesAnalysis',
        component: () => import('@/views/storeEarlywarning/salesAnalysis')
      },
      {
        path: '/earlywarningAnalysis/rentAnalysis',
        name: 'rentAnalysis',
        component: () => import('@/views/storeEarlywarning/rentAnalysis')
      },
      {
        path: '/earlywarningAnalysis/projectComparison',
        name: 'ProjectComparison',
        component: () => import('@/views/storeEarlywarning/projectComparison/index.vue'),
        children: [{
          path: '/earlywarningAnalysis/projectComparison',
          name: 'projectComparison',
          component: () => import('@/views/storeEarlywarning/projectComparison/selectProject.vue')
        },
        {
          path: '/earlywarningAnalysis/comparisonContent',
          name: 'comparisonContent',
          component: () => import('@/views/storeEarlywarning/projectComparison/comparisonContent.vue')
        }]
      },
      {
        path: '/earlywarningAnalysis/sitingAnalysis',
        name: 'sitingAnalysis',
        component: () => import('@/views/storeEarlywarning/sitingAnalysis')
      },
      {
        path: '/earlywarningAnalysis/evaluationAnalysis',
        name: 'evaluationAnalysis',
        component: () => import('@/views/storeEarlywarning/evaluationAnalysis')
      },
      {
        path: '/earlywarningAnalysis/merchantsRecommend',
        name: 'merchantsRecommend',
        component: () => import('@/views/storeEarlywarning/merchantsRecommend')
      }
    ]
  },
  {
    path: '/enterprise',
    name: 'enterprise',
    component: () => import('@/views/enterpriseBrandLibrary/index.vue')
  },
  {
    path: '/enterprise/brandDetail',
    name: 'enterpriseBrandDetail',
    component: () => import('@/views/enterpriseBrandLibrary/brandDetail/index.vue')
  },
  {
    path: '/workOrderCenter',
    name: 'workOrderCenter',
    component: () => import('@/views/workOrderCenter/index.vue'),
    children: [{
      path: '/workOrderCenter',
      meta: { title: '工单中心' },
      component: () => import('@/views/workOrderCenter/workCenter')
    }, {
      path: '/workSubmit',
      name: 'workSubmit',
      meta: { title: '工单提交' },
      component: () => import('@/views/workOrderCenter/workCenter/workSubmit.vue')
    }, {
      path: '/formSubmit',
      meta: { title: '表单提交' },
      component: () => import('@/views/workOrderCenter/workCenter/formSubmit.vue')
    }, {
      path: '/workSetting',
      name: 'workSetting',
      meta: { title: '工单设置' },
      component: () => import('@/views/workOrderCenter/workSetting')
    }, {
      path: '/teamManagement',
      meta: { title: '工作组管理' },
      component: () => import('@/views/workOrderCenter/teamManagement')
    }]
  },
  {
    path: '/basicForm',
    name: 'basicForm',
    meta: { title: '编辑流程' },
    component: () => import('@/views/workOrderCenter/basicForm/index.vue')
  },
  {
    path: '/basicFormTest',
    name: 'basicFormTest',
    meta: { title: '编辑流程' },
    component: () => import('@/views/workOrderCenter/basicFormTest/index.vue')
  },
  {
    path: '/formConfiguration',
    name: 'formConfiguration',
    meta: { title: '表单设置' },
    component: () => import('@/views/workOrderCenter/dynamicForm/formConfiguration.vue')
  },
  {
    path: '/bpmn',
    name: 'bpmn',
    component: () => import('@/views/bpmn/index.vue')
  },
  {
    path: '/knowledgeBase',
    name: 'knowledgeBase',
    component: () => import('@/views/knowledgeBase/index.vue'),
    children: [
      {
        path: '/knowledgeBase/*',
        component: {},
        name: 'article'
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

const originalPush = VueRouter.prototype.push
// push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) { return originalPush.call(this, location, onResolve, onReject) }
  return originalPush.call(this, location).catch(err => err)
}

export default router
